@import "../../App/variables";

.tooltip-container {
    position: relative;

    .element-with-tooltip {
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        color: #333;
        padding: 5px;
        border-radius: 4px;
        display: none;
        background-color: white !important;
        box-shadow: 0 2px 6px rgba(0, 0, 18, 0.16);

        .content {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            color: #333 !important;
            font-size: $font-size-small !important;
        }
        &.left {
            top: 0%;
            left: -170px;
            transform: translateY(-50%);
        }

        &.top {
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
        }

        &.right {
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
        }

        &.bottom {
            top: -5px;
            left: -200px;
            transform: translateY(10px);
            z-index: 1;
        }
    }

    &:hover {
        .tooltip {
            display: block;
        }
    }
}
