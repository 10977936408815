@import "./../../../../App/variables";
@import "./../../../../App/placeholders";

.data {
    display: flex;
    border-bottom: $border-grey-light;

    &.columns {
        margin: 0;

        &:last-child {
            margin-bottom: $spacing-5;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    & > .column {
        padding-left: 0;
    }

    & .dt {
        @extend %has-first-letter-uppercase;
    }

    & .dd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0;
    }

    & + .table,
    .table + & {
        margin-top: $spacing-5;
    }
}
