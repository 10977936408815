@import "../App/variables";

.error-ie {
    margin: 4rem 0;
    text-align: center;
    font-family: $segoe;
    font-size: 1.2rem;

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        color: red;
    }

    &__browsers {
        display: flex;
        justify-content: center;
    }

    &__magiclink-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__browser {
        padding: $spacing-4;
        font-weight: bold;
    }

    &__link-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__link {
        max-width: 600px;
        margin-bottom: $spacing-4;
        padding: $spacing-2 $spacing-4;
        text-align: left;
        word-break: break-all;
        font-size: $font-size-base;
        background-color: #fff;
    }
}
