@import "../variables";
@import "../placeholders";

.app-header {
    padding: 0;
    background-color: $white;

    .container {
        padding: 0 $padding-app-x;
        box-shadow: 0px 6px 6px rgba(100, 100, 100, 0.06);
    }

    &.columns {
        margin-bottom: 0;
    }
}

.header {
    &__items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    &__home-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 80px;
        flex-wrap: wrap;
        gap: 16px;
        padding: $spacing-2 0;
        &:hover {
            text-decoration: none;
        }
        @media print {
            display: flex !important;
            flex-direction: row !important;
            padding: auto;
        }
        @media (max-width: 600px) {
            min-height: 150px !important;
        }
    }
    &__logo_marianne {
        width: 100%;
        height: 100%;
        @media (max-width: 600px) {
            width: 80% !important;
        }
    }

    &__logo_FCE {
        display: flex;
        justify-content: center;
        align-items: center;
        &-repub {
            margin-right: 2.5rem;
            @media (max-width: 600px) {
                margin-right: 0 !important;
            }
        }
        .logo-wrapper {
            @media (max-width: 600px) {
                margin-left: -$spacing-5 !important;
            }
        }
    }
    &__mailto {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .icon-button-link {
            a {
                text-align: center;
                height: 18px;
                width: 18px;
            }
            &:hover {
                background-color: #f5f5f5 !important;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            color: inherit;
            text-decoration: none;
            background-color: transparent !important;
            border: none !important;
            width: 2rem;
            height: 2rem;
            text-align: center;
            cursor: pointer;
        }
    }

    &__title {
        font-family: $roboto;
        font-size: $font-size-section-title;
        font-weight: bold;
        color: $dark-grey !important;
        margin-left: $spacing-3;
        @media (max-width: 600px) {
            font-size: $font-size-base-small;
        }
        @media print {
            font-size: $font-size-base;
            margin-left: 0;
        }
    }
    &__sub_title {
        font-family: $roboto;
        font-size: $font-size-small;
        font-weight: normal;
        color: $black-grey !important;
        margin-left: $spacing-3;
        @media print {
            font-size: font-size-base;
            margin-left: 0;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 80px;

        .button {
            width: 13rem;
            height: 2.25rem;
            font-weight: bold;

            &:not(:last-of-type) {
                margin-right: $spacing-4;
            }

            &-icon {
                margin-right: $spacing-2;
            }
        }
    }
}

.beta-message {
    @extend %flex-center;
    font-size: 1rem;
    letter-spacing: 0.04rem;
    color: #0063cb;
    background-color: #e8edff;
    padding: 1rem;
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.1);
    &__nouveaute {
        font-weight: bold;
    }
    &__feedback-link {
        margin-left: $spacing-1;
        padding: $spacing-1 $spacing-2;
        color: #0063cb !important;
        transition: background-size 0s;
        background-color: #b9c7f3;
        border-radius: $border-radius-1;

        &:hover {
            color: $white;
            background-color: #a9bfff;
        }
    }
}
