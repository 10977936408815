@import "../../../../App/variables";

.see-details-link {
    width: 9rem;
    text-align: center;

    &__link {
        font-size: 0.9rem;
        &.list {
            display: flex;
        }
    }

    &__icon {
        margin-right: $spacing-2;
    }
}
.details-link {
    font-family: $marianne;
    font-size: $font-size-base-small;
    font-weight: 400;
    line-height: 1.5rem;
    color: $title-grey;
    &-text {
        display: flex;
        align-items: center;
        text-transform: capitalize;
    }
    a {
        color: $dark-blue !important;

        text-decoration: underline !important;
        text-underline-offset: 4px;
        &:hover {
            text-decoration-thickness: 2px !important;
        }
    }
}
