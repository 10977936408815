@import "../../App/variables";

.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 0.5rem 0rem 0.25rem;
    border-radius: 0.25rem;
    gap: 0.25rem;
    font-size: $font-size-base-small;
    font-family: $marianne;
    line-height: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    max-width: 100px;
    &__small-text {
        font-size: 0.75rem !important;
        font-weight: 700 !important;
    }
    &__span {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__icon--success {
        background: #b8fec9;
        color: #18753c;
    }
    &__icon--danger {
        color: #ce0500;
        background: #ffe9e9;
    }
    .badge-icon {
        display: inline-flex;
        align-items: center;
        margin-right: 0.5rem;
    }
}
