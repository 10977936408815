@import "./../../components/App/variables";

.all_effectifs_etp_button {
    display: flex;

    padding-bottom: $spacing-3;
    button {
        border: none !important;
        padding: 0 1rem 0 0;
        background-color: transparent;
        color: $dark-blue !important;
        font-family: $marianne;
        font-size: $font-size-base;
        font-weight: 400;
        line-height: 1.5rem;
        text-decoration: underline !important;
        text-underline-offset: 4px;
        text-decoration-thickness: 1px !important;
        display: flex;
        gap: $spacing-2;

        cursor: pointer;
        &:hover {
            text-decoration-thickness: 2px !important;
        }
        > .icon {
            width: 25px;
            height: 25px;
            margin-right: $spacing-4;
        }
    }
}
