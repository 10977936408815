@import "../App/variables";
@import "../App/placeholders";
@import "~bulma/sass/elements/button.sass";

.login {
    padding: $spacing-6 $padding-app-x;

    &__message {
        max-width: 608px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $marianne;

        @include from(425px) {
            text-align: center;
        }

        &--form {
            max-width: 38rem;
            text-align: left;
        }
        .access-form {
            gap: $spacing-4;
            display: flex;
            flex-direction: column;
        }
    }

    &__title {
        color: $dark-blue;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.2;
        max-width: 34rem;
        font-family: $marianne;
        margin-bottom: $spacing-4;

        @include from($tablet) {
            font-size: 2rem;
            margin-bottom: $spacing-5;
        }
    }

    &__head-form {
        margin-bottom: $spacing-4;

        p {
            margin-bottom: $spacing-2;
        }
    }

    &__subtitle {
        font-family: $evolventa;
        font-size: $font-size-base;
        line-height: 1.3;
        max-width: 38rem;
        margin-bottom: $spacing-4;

        @include from($tablet) {
            font-size: 1.25rem;
        }
    }

    &__info {
        margin-bottom: $spacing-5;
        line-height: 1.2;
    }

    &__mailing-list {
        margin: $spacing-5 0;

        input {
            margin-right: $spacing-2;
            font-size: 2rem;
        }
    }
    &__code-input {
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0.2em;
    }
    &__code-input,
    &__mail-input {
        box-shadow: inset 0 -2px 0 0 $dark-blue !important;
        margin: 0;
        max-height: 2.5rem;
        background-color: #eee;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:focus,
        &:active {
            outline: 5px solid #0a76f6 !important;
            outline-offset: 3px !important;
            outline-width: 2px !important;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__button {
        width: 200px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $marianne;
        border-radius: $border-radius-1;

        &--as-link.button {
            width: unset;
            height: unset;

            > span {
                font-weight: normal;
            }

            &,
            &:hover,
            &:focus {
                background-color: transparent;
                border: none;

                &:not(:active) {
                    box-shadow: none;
                }
            }

            &:hover,
            &:focus {
                color: $dark-blue !important;
                text-decoration: underline;
            }
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: $spacing-5;
        > a {
        
                gap: $spacing-2;
            
            &:hover{
                color: $dark-blue !important
            }
        }

        > button,
        > a {
            color: $dark-blue !important;
            padding-left: 0 !important;
          
        }
    }

    &__link {
        @extend .button;
        font-family: $marianne;
        color: $dark-blue !important;

        &,
        &:hover,
        &:focus {
            background-color: transparent;
            border: none;
            color: $dark-blue !important;
        }

        &:hover,
        &:focus {
            outline: none;
            text-decoration: underline;
            color: $dark-blue !important;

            &:not(:active) {
                box-shadow: none;
            }
        }
    }

    &__notif {
        display: flex;
        align-items: baseline;
        margin-bottom: $spacing-4;
        padding: $spacing-2;
        font-family: $roboto;
        font-size: 14px;
        border-radius: $border-radius-1;

        &--success {
            @extend %success;
        }

        &--info {
            @extend %info;
            & strong {
                font-weight: bold;
                color: inherit;
            }

            & h5 {
                font-weight: bold;
                color: inherit;
            }
        }

        &--error {
            @extend %error;
            white-space: break-spaces;
        }

        > .svg-inline--fa {
            position: relative;
            top: 1px;
            width: 16px;
            margin-right: $spacing-2;
        }
    }

    .label {
        font-family: $roboto;
        font-weight: bold;
    }

    @media screen and (max-width: 600px) {
        .field.has-addons {
            display: block;

            .control {
                margin-right: 0;
                margin-bottom: $spacing-2;

                button,
                input {
                    border-radius: 0.25rem !important;
                }

                button {
                    width: 100%;
                }
            }
        }
    }
}
