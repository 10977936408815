@import "./../../../../App/variables";
@import "./../../../../App/placeholders";

.dashboard {
    margin-top: $spacing-3;
    .columns {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: $spacing-3;
        margin-bottom: $spacing-3 !important;

        .dashboard-item {
            margin-bottom: $spacing-2;
            border: solid 1px #ddd;
            padding: $spacing-1;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: $spacing-5;
            @media not print {
                min-height: 6rem;
            }
            &-icon {
                width: 1rem;
            }
            &__data {
                flex: 1;

                &__header {
                    margin-bottom: $spacing-3;
                    display: flex;
                    justify-content: flex-start;
                    text-align: start;
                    flex-direction: column;
                    font-family: $marianne;
                    line-height: 1.5rem;
                    @media print {
                        display: flex !important;
                        flex-direction: row !important;
                        align-items: center !important;
                        justify-content: center !important;
                    }

                    &-label {
                        font-size: $font-size-base;
                        font-weight: bold;
                        color: $title-grey;
                        margin-right: $spacing-2;
                    }
                    &-value {
                        font-family: $marianne;
                        color: $default-text-gey !important;
                        font-size: $font-size-small;
                        font-weight: 700;
                        ul {
                            list-style-type: disc;
                        }
                        .text {
                            font-family: $marianne;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.5rem;
                            color: $title-grey;
                        }
                    }
                }

                @extend %flex-start;
                flex-direction: column;

                &--small-text {
                    font-size: 0.9rem;
                }
            }
        }
    }
}
