@import "../../../../../App/variables";

.rcs-observations {
    list-style: circle;

    &__item {
        margin-bottom: $spacing-2;
    }

    &__button-label {
        padding-left: $spacing-2;
    }

    &__button {
        color: $dark-blue;
        text-decoration: none;
        border: 0;
        background-color: transparent;
        font-size: $font-size-base;
        padding-left: 0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $dark-blue;
        }
    }
}
