@import "../../../../App/variables";
.chart {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 18, 0.16);
    border-radius: 0.5rem;
    padding: 1.5rem;
    .effectif-chart-legend {
        flex: 1 3;
        font-size: $font-size-small;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: .5rem;
        color: $title-grey;
        @media print {
            display: none !important;
        }

        div {
            display: flex;
            gap: $spacing-2;
            align-items: center;
            input[type="checkbox"] {
                box-sizing: border-box;
                padding: $spacing-4 0 !important;
                accent-color: $dark-blue !important;
                width: 20px !important;
                height: 20px !important;
            }
        }
    }
    &-selec-data-source {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 18px;
        row-gap: 16px;
    }
    .select-periode {
        select {
            height: 2.5rem;
            width: 5.5rem;
            border: none;
            border-bottom: 2px solid #3a3a3a;
            background-color: #eeeeee;
            display: flex;
            justify-content: center !important;
            align-items: center !important;

            svg {
                left: 1rem !important;
            }
            &:focus-visible {
                border: none !important;
                border-bottom: 2px solid #3a3a3a;
            }
        }
    }
}
