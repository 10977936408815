@import "../../../../App/variables";
.data-sheet__print-section {
    .print-btn {
        background-color: white !important;
        border: 1px solid $default-grey!important;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
    }
}
