@import "../App/variables";
@import "../App/placeholders";

%search-button {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: transparent !important;
    border: none !important;
}

.app-search {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__wrapper {
        & .container {
            padding: $padding-app-x;
        }
    }

    .search-form {
        padding: $spacing-4;
        .label {
            height: $spacing-5;
        }
        .description {
            padding: $spacing-4;
            margin-bottom: $spacing-4;
            text-align: center;
            font-family: $marianne;
            font-size: 1rem;
            font-style: normal;
            font-weight: bold;
            line-height: 1.5rem;
            color: $default-text-gey;
        }

        &__search {
            display: flex;
            align-items: flex-start;
        }

        &__search-bar {
            display: flex;
            align-items: center;
            max-width: 100%;
            width: 100%;
            color: $dark-grey;
            border-bottom: 2px solid $dark-blue;
            border-top-right-radius: $border-radius-1;
            border-top-left-radius: $border-radius-1;

            @media not print {
                box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
            }

            .input {
                border: none;
                box-shadow: none;
                background-color: $contrast-grey !important;
                font-family: $marianne;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                &:focus,
                &:active {
                    outline: 5px solid #0a76f6 !important;
                    outline-offset: 3px !important;
                    outline-width: 2px !important;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            input::placeholder {
                color: $title-grey;
                font-family: $marianne;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
            }

            .button {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ccc;
                background: none;

                &:hover {
                    color: #999;
                }
            }
        }

        &__toolbar {
            display: flex;
            justify-content: space-between;
        }

        &__buttons {
            display: flex;
            flex-direction: column;

            &--align-top {
                justify-content: flex-start;
            }

            .action.button {
                border: 1px solid #fff;
                padding: 0.625rem 1.5rem;
                background-color: $dark-blue;
                color: white;
                font-family: $marianne;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.75rem;
            }

            > div {
                width: 100%;
            }
        }

        &__reset {
            display: flex;
            justify-content: flex-end;
            padding: 0 !important;
            .button {
                @extend %search-button;
                background: transparent;
                font-family: $marianne;
                color: $dark-blue !important;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                margin-top: $spacing-4;
                border: none;
                text-decoration: underline;

                &:hover,
                &::selection {
                    background-color: transparent;
                    border: none;
                }
            }
        }

        .field {
            width: 100%;
            margin-bottom: 0;
            display: flex;
            &-input {
                display: flex;
                gap: $spacing-3;
            }
            .control {
                width: 100%;
            }

            .select_placeholder {
                color: $title-grey;
                font-size: 1rem;
                font-style: $marianne;
                font-weight: 400;
                line-height: 1.5rem;
            }
        }

        .filters__checkboxes {
            display: flex;
            flex-wrap: wrap;

            .filter__siege {
                display: flex;
                flex-direction: column;
                margin-top: -$spacing-4;
                label,
                span {
                    font-family: $marianne;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    color: $title-grey;
                    text-overflow: ellipsis;
                }

                &_button {
                    display: flex;
                    gap: $spacing-3;
                }
            }

            .filter-state {
                display: flex;
                flex-direction: column;
                margin-top: -$spacing-4;

                .label {
                    font-family: $marianne;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    color: $title-grey;
                }
            }
        }

        .horizontal-separator {
            margin: $spacing-4 0;
            border-bottom: 1px solid $default-grey !important;
        }

        .filters__left {
            margin-top: 0 !important;
        }
        &,
        .filters__checkboxes,
        .filters__selects {
            .label {
                font-weight: bold;
                margin-right: $spacing-4;
                margin-bottom: $spacing-4;

                &.label--state {
                    font-weight: normal;
                }
            }
        }
        .search_btn {
            display: flex;
            align-items: center;
            .button {
                background-color: $dark-blue;
                color: #f5f5fe;
                border-radius: 0;
                padding: 0.625rem 1.5rem;
                font-family: $marianne;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.75rem;
            }
            .reset_button {
                background: transparent;
                font-family: $marianne;
                color: $dark-blue !important;
                padding: 0.625rem 1.5rem;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.75rem;
                border: none;
                cursor: pointer;
                text-decoration: underline;

                &:hover,
                &::selection {
                    background-color: transparent;
                    border: none;
                }
            }
        }
    }

    /* -------------------------------------- */
    /* ----- react-accessible-accordion ----- */
    /* -------------------------------------- */

    .accordion__heading {
        width: 12rem;
    }

    .accordion__button {
        cursor: pointer;
        width: 100%;
        text-decoration: underline;
        text-align: left;
        border: none;

        &:focus {
            outline: none;

            span {
                box-shadow: 0 0 4px $white;
            }
        }
    }

    .accordion__button:before {
        position: relative;
        left: 2px;
        display: inline-block;
        content: "";
        height: 12px;
        width: 12px;
        margin-right: $spacing-4;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(45deg);
        transition: transform $duration-2;
    }

    .accordion__button[aria-expanded="true"]::before,
    .accordion__button[aria-selected="true"]::before {
        transform: rotate(-135deg) translate(-0.1rem, -0.1rem);
    }

    .accordion__panel {
        padding-top: $spacing-4;
        animation: fadein $duration-2 ease-in;
    }

    /* --------------------- */
    /* ----- Animation ----- */
    /* --------------------- */

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
