@import "../App/variables";
@import "../App/placeholders";

.app-search-results {
    &.container {
        padding: 0 $padding-app-x;
    }

    padding-top: $spacing-4;

    & > .columns {
        margin: 0;
    }

    &__title {
        width: 80%;
        margin: $spacing-2 auto;
        font-family: $marianne;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.25rem;
        color: $black;
        text-align: center;
    }
    &__title2 {
        font-family: $marianne;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 2rem;
        color: $black;
        text-align: center;
    }

    &__export-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__export-button {
        @extend %is-dark-blue;
        font-family: $marianne;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.75rem;
        display: flex;
        gap: $spacing-2;
        padding: $spacing-1 $spacing-2 $spacing-1 $spacing-2;
        cursor: pointer;
        border: 1px solid $dark-blue !important;
    }

    .result-row {
        overflow: auto;

        @media print {
            justify-content: left;

            > div {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .notification.is-light {
        margin-top: $spacing-4;
        background-color: #d4ecff;
        color: #1d72aa;
    }
}
