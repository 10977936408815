@import "../../App/variables";
@import "../placeholders";

.app {
    .footer {
        padding: 0;
        background-color: $white;
        box-shadow: 0 -1px 0 0 $dark-blue;

        &-row:not(:first-of-type) {
            border-left: 1px solid $footer-link;
            padding-left: 1.5rem;
        }

        .container {
            display: flex;
            flex-direction: column !important;
        }

        &__gouv {
            display: flex;
            flex-direction: row !important;
            padding: 1rem 4.25rem;
            @media (max-width: 600px) {
                padding: $spacing-1;
            }
            
        }
        &__gouv-logo {
            background: #fff;
        }

        &__links {
            display: flex;
            padding: 1rem 7.5rem;
            align-items: flex-start;
            flex-flow: row wrap;
            gap: 1rem;
            color: $footer-link !important;
            font-size: $font-size-small !important;
            line-height: 1.25rem !important;
            font-family: $marianne;
            @media (max-width: 600px) {
                padding: $spacing-3;
            }
            a {
                color: $footer-link !important;

                &:hover {
                    color: $dark-blue !important;
                    text-decoration: underline !important;
                    text-underline-offset: 2px;
                }
            }

            &-row {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                height: 1rem;
            }

            &-row:not(:first-of-type) {
                box-shadow: -1px 0 0 0 $default-grey;
                padding-left: 1rem;
            }
        }
    }
    .second-footer {
        box-shadow: 0 -1px 0 0 $default-grey !important;
    }
}
