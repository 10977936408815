%is-grey {
    color: $grey !important;
    background-color: $white;
    border-color: $grey !important;
}
%is-dark-blue {
    color: $dark-blue !important;
    background-color: $white;
}

%has-first-letter-uppercase {
    &::first-letter {
        text-transform: uppercase;
    }
}

%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
%flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

%success {
    color: $info-success;
    border: 1px solid $info-success;
    background-color: $info-success-bg;
}

%error {
    color: $info-error;
    border: 1px solid $info-error;
    background-color: $info-error-bg;
}

%info {
    color: $info-neutral;
    border: 1px solid $info-neutral;
    background-color: $info-neutral-bg;
}
