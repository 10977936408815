@import "../App/variables";
@import "~bulma/sass/elements/button.sass";

%at-footer-button {
    @extend .is-white;
    color: #4c4c4c !important;
    border: 0;
    height: 3rem !important;
    width: 30%;
}

.table.at {
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius-2;

    th {
        color: $title-grey;

        &:not(:last-of-type) {
            border-right: $border-grey-light;
        }
    }

    .at__head {
        &__tr {
            border-radius: $border-radius-2;
            background-color: $background-default-grey-hover !important;
            @media not print {
                box-shadow: $box-shadow-awesome-table;
            }
        }

        &__th {
            vertical-align: middle;
            white-space: nowrap;
            background-color: $background-default-grey-hover !important;
            border-bottom: 2px solid #3a3a3a !important;
            border-right: none !important;
            font-family: $marianne;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;
            color: $title-grey;
            padding: $spacing-4;

            &--important {
                color: $primary;
                border: $border-grey-light;
                border-bottom: 1px solid $primary;
                line-height: 2rem;
                border-radius: $border-radius-2;

                @media not print {
                    box-shadow: $box-shadow-awesome-table;
                }
            }

            &--is-sortable {
                cursor: pointer;
            }

            .svg-inline--fa {
                margin-left: $spacing-2;
            }
        }
    }

    .at__body {
        &__tr {
            cursor: pointer;
            border-left: none !important;
            border-right: none !important;
            height: 2.7rem;
            margin: auto 0;

            &:hover {
                background-color: #f5f5fe;
            }
        }

        &__th,
        &__td {
            padding: $spacing-3;
            border-top: $border-grey-light;
            font-family: $marianne;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            color: $default-text-gey;
            vertical-align: middle;

            a {
                font-weight: 700 !important;
                color: $default-text-gey;
            }

            &:nth-of-type(4) {
                min-width: 12rem;
            }

            &:nth-of-type(6) {
                text-align: center;
            }
        }

        &__th {
            font-weight: bold;
            background-color: $light;
            color: $primary;
        }
    }

    .at__footer {
        &__tr {
            border: none !important;
            border-top: 0;
        }

        &__td {
            text-align: center;
            border-width: 1px 0 0;

            .pageNav {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                @media print {
                    display: none !important;
                }
                button {
                    margin-left: $spacing-4;
                    font-size: 0.875rem !important;
                    font-weight: 500 !important;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    padding: 0.5rem 0.75rem !important;
                    font-family: $marianne;
                }
                .prev-btn {
                    align-items: center;
                    display: flex;
                    gap: $spacing-2;
                }
                button:hover {
                    background-color: $background-default-grey-hover;
                }
                .active {
                    background-color: $dark-blue;
                    color: white;
                }
                .active:hover {
                    background-color: $primary;
                }

                .prev-button .button-icon {
                    margin-right: $spacing-2;
                }

                .next-button .button-icon {
                    margin-left: $spacing-2;
                }
            }

            .pager {
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 40%;
                height: 2.5rem;

                &__label {
                    margin-right: $spacing-2;
                }

                .select {
                    margin-right: $spacing-1;
                }
            }

            .prev-button {
                @extend %at-footer-button;
                background-color: #e6e6e6 !important;

                &:hover {
                    background-color: darken(#e6e6e6, 10%) !important;
                }
            }

            .next-button {
                @extend %at-footer-button;
                background-color: #ccc !important;

                &:hover {
                    background-color: darken(#ccc, 10%) !important;
                }
            }
        }
    }
}
