@import "../../App/variables";

.info-box {
    margin: 0 0 $spacing-4 -0.2rem; 

    &__pill {
        padding: $spacing-2 $spacing-3;
        font-weight: bold;
        background-color: #e3e3fd;
        color: $dark-blue;
        border-radius: 1rem;
        font-family: $marianne;
        font-size: $font-size-small;
        cursor: pointer;

        &:hover {
            background-color: #c5c5e1;
        }
    }

    &__print {
        display: none;
    }
}
