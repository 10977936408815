@import "./../../../../App/variables";
@import "./../../../../App/placeholders";

.psi {

    &__data {
        .dd {
            display: flex;
            justify-content: center;
        }
    }
    &__name {
        font-family: $marianne;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem;
        color: $title-grey;
    }
    &__description {
        font-family: $marianne;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        color: $title-grey;

        ul {
            list-style: inside;
        }
    }
}

.psi-siren-table.table {
    margin-top: 0;
}

.psi-siret {
    &__label {
        font-weight: 700;
    }

    &__button-wrapper {
        padding: $spacing-3;

        .button {
            width: 20rem;
        }
    }

    &__establishment-count {
        padding: $spacing-3 $spacing-3 $spacing-2;
        font-family: $marianne;
        font-size: 0.875rem;
        font-style: normal;
        line-height: 1.5rem;
        color: $title-grey;
        font-weight: bold;
    }

    &__table.table {
        margin: 0 0 $spacing-5;

        .psi-siret__table-cell-year {
            font-size: $font-size-small;
            font-weight: bold;
            text-align: center;
        }
    }

}
