@font-face {
    font-family: "Segoe Pro";
    src: url("../../assets/fonts/SegoePro.eot");
    src: url("../../assets/fonts/SegoePro.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/SegoePro.woff2") format("woff2"),
        url("../../assets/fonts/SegoePro.woff") format("woff"),
        url("../../assets/fonts/SegoePro.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Segoe Pro";
    src: url("../../assets/fonts/SegoeUI-Bold.eot");
    src: url("../../assets/fonts/SegoeUI-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/SegoeUI-Bold.woff2") format("woff2"),
        url("../../assets/fonts/SegoeUI-Bold.woff") format("woff"),
        url("../../assets/fonts/SegoeUI-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
  }

@font-face {
    font-family: "Evolventa";
    src: url("../../assets/fonts/Evolventa-Regular.eot");
    src: url("../../assets/fonts/Evolventa-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/Evolventa-Regular.woff2") format("woff2"),
        url("../../assets/fonts/Evolventa-Regular.woff") format("woff"),
        url("../../assets/fonts/Evolventa-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Evolventa";
    src: url("../../assets/fonts/Evolventa-Bold.eot");
    src: url("../../assets/fonts/Evolventa-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../assets/fonts/Evolventa-Bold.woff2") format("woff2"),
        url("../../assets/fonts/Evolventa-Bold.woff") format("woff"),
        url("../../assets/fonts/Evolventa-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Regular.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Bold.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Regular_Italic.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Regular_Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Bold_Italic.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Bold_Italic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-ExtraBold.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-ExtraBold_Italic.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-ExtraBold_Italic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Light.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Light_Italic.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Light_Italic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Medium.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Medium_Italic.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Medium_Italic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Thin.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Marianne";
    src: url("../../assets/fonts/Marianne-Thin_Italic.woff2") format("woff2"),
         url("../../assets/fonts/Marianne-Thin_Italic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
  }