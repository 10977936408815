@import "../../../../../App/variables";
$border-color: var(--border-color);

.enterprise-finances {
    &__not-available {
        margin-top: $spacing-2;
        font-family: $marianne;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        color: $dark-grey;
    }
}
.chart-wrapper {
    position: relative;
    display: flex;
    min-height: 17.625rem;
    flex-wrap: wrap;
    width: 100%;
    margin: 1em 0 !important;
    border-radius: 0.75rem;
    border: 1px solid #e6edff;
    padding: 1rem;
    @media print {
        width: 100% !important;
        border: none;
        margin: 0 !important;
        height: 100% !important;
    }
    .chart-graph {
        flex: 1;
        width: 100% !important;
        @media (max-width: 600px) {
            border: none;
            padding: 0;
            width: 100%;
        }
        @media print {
            height: 80% !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}
.horizontal-chart-legend {
    font-size: 0.7rem;
    font-weight: 400;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;

    gap: $spacing-4;
    color: #000;
    font-family: $marianne;
    div {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        label {
            margin-left: 5px;
        }
    }
}
.finance-chart-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    min-height: 17.625rem;

    width: 100%;
    @media print {
        height: 300px !important;
        width: 100% !important;
        margin: 1rem !important;
    }

    .chart-legend {
        flex: 1 3;
        font-size: $font-size-base-small;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        color: $title-grey;
        @media print {
            display: none !important;
        }

        div {
            margin-bottom: 5px;
            display: flex;
            gap: $spacing-2;
            align-items: center;
            input[type="checkbox"] {
                box-sizing: border-box;
                padding: $spacing-4 0 !important;
                accent-color: $dark-blue !important;
                width: 20px !important;
                height: 20px !important;
            }
        }
    }

    .chart-graph {
        flex: 3 1;
        flex-basis: 3;
        width: 100% !important;
        border-radius: 0.75rem;
        border: 1px solid #e6edff;
        padding: 1rem;
        @media (max-width: 600px) {
            border: none;
            padding: 0;
            width: 100%;
        }
        @media print {
            height: 80% !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}
