@import "../App/variables";
@import "bulma/sass/utilities/mixins";

@keyframes dotsLoader {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-4px);
    }

    100% {
        transform: translateY(0px);
    }
}

.home-page {
    font-family: $marianne;
    background-color: white !important;
    margin-top: $spacing-4;
    h2 {
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
        margin: $spacing-4;
        font-family: $marianne;
    }

    h3 {
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: $spacing-3;
        line-height: 2.5rem;
        font-family: $marianne;
        color: $title-grey;
    }

    p {
        font-size: 1.25rem;
        font-family: $marianne;
        color: $title-grey;
    }

    &__white-title {
        color: $white;
        font-family: $marianne;
    }

    &__blue-title {
        color: $title-grey;
        font-family: $marianne;
    }

    .columns {
        margin-bottom: 0;
    }

    &__section {
        padding: 4rem;
        @media (max-width: 600px) {
            padding: $spacing-4 !important;
        }
    }

    &__summary {
        color: $dark-blue;
        text-align: center;
        font-family: $marianne;
        padding: 6rem;

        @media (max-width: 600px) {
            padding: $spacing-4 $spacing-1 !important;
        }
    }

    &__action-links-container {
        flex-wrap: wrap;
        background-color: transparent;
        padding: $spacing-2 $spacing-4;
        font-family: $marianne;
        @media (max-width: 600px) {
            padding: $spacing-2 !important;
        }
        .home-page__link {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .divider-column {
            align-items: center !important;
            justify-content: center !important;
            .divider {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &-text {
                    font-size: 1.5em;
                    color: $dark-blue;
                    text-align: center;
                }
            }
        }

        .content {
            flex: 1 1 !important;
            filter: drop-shadow(0 1px 3px rgba(0, 0, 18, 0.16));
            background-color: $white-smoke !important;
        }

        .text-title {
            color: $white-smoke;
            font-size: 1rem;
            font-family: $marianne;
            line-height: 1.5rem;
            font-weight: 500;
            text-align: center;
            padding: $spacing-2;
            background-color: $dark-blue;
        }
        .text-explicatif {
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.25rem;
            color: $title-grey !important;
            padding: 0 $spacing-4 $spacing-4;
            text-align: center;
        }
    }

    .icons {
        display: flex;
        text-align: center;
        font-size: 1.65rem;
        font-weight: 600;
        gap: $spacing-3;
        flex-wrap: wrap;
        flex: 1 1 1 !important;

        &__item {
            position: relative;
            justify-content: center;
            background-color: $white-smoke !important;
            filter: drop-shadow(0 1px 3px rgba(0, 0, 18, 0.16));
            display: flex;
            cursor: pointer;
            flex: 1 1 !important;
            padding: $spacing-5;
        }
        .first-text {
            color: $dark-blue;
        }
        &__image-container {
            justify-content: center;
            align-items: center;
            margin-bottom: $spacing-4;
        }

        .dots {
            display: flex;
            justify-content: center;

            div {
                background-color: $deep-blue;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                border: 1px solid $deep-blue;
                margin: 0 2px;

                &:nth-child(1) {
                    animation: dotsLoader infinite ease-in-out;
                    animation-duration: 1s;
                }
                &:nth-child(2) {
                    animation: dotsLoader infinite ease-in-out;
                    animation-duration: 1.05s;
                }
                &:nth-child(3) {
                    animation: dotsLoader infinite ease-in-out;
                    animation-duration: 1.1s;
                }
            }
        }
    }

    .particles {
        width: 100%;
        background: #f5f5fe;
    }

    &__how-it-work {
        padding: $spacing-5 6rem $spacing-6 6rem;
        @media (max-width: 600px) {
            padding: $spacing-6 !important;
        }

        h2 {
            color: $dark-blue !important;
        }
        &__item {
            display: flex;
            color: $dark-blue !important;
            &_column {
                display: flex;
                align-items: center;
                gap: 1rem;
            }
            h4 {
                font-size: 1.25rem;
                font-weight: 700;
                padding-bottom: 1rem;
                color: $dark-blue !important;
            }

            span {
                font-size: 5rem;
                font-family: $marianne;
                font-weight: 600;
                text-align: right;
            }

            p {
                font-size: 1.5rem ;
                font-family: $marianne;
                font-weight: 700;
            }
        }
    }

    &__daily-use {
        &__items {
            display: flex;
            flex: 1 1 1;
            gap: $spacing-3;
            flex-wrap: wrap;
        }

        &__item {
            filter: drop-shadow(0 1px 3px rgba(0, 0, 18, 0.16));
            background-color: $white-smoke !important;
            display: flex;
            flex-direction: column;
            flex: 1 1 !important;
            position: relative;
            cursor: pointer;
            padding: $spacing-6 $spacing-4;
            &-content {
                padding: $spacing-1;
            }
            h4 {
                font-size: 1.15rem;
                font-weight: 700;
                text-align: center;
                margin-bottom: 3rem;
                color: $dark-blue;
                padding: $spacing-3;
            }

            p {
                font-size: 1rem;
                font-weight: 400;
                color: darken($title-grey, 10%);
            }
        }

        &__quote {
            text-align: center;
            padding: 3rem 0;

            :first-child {
                font-size: 1.75rem;
                font-weight: bolder;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 2rem;
        box-shadow: inset 0 0 0 0, inset 0 -0.15rem 0 0 $dark-blue !important;
        background-color: #f5f5fe !important ;

        p {
            font-size: 2.25rem;
            font-weight: bolder;
            line-height: 2.5rem;
            color: $dark-blue;
            font-family: $marianne;
            @media (max-width: 600px) {
                font-size: $font-size-base;
                font-weight: bolder;
            }
        }
    }

    &__link {
        margin: 2rem 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
            padding: $spacing-2 $spacing-4;
            color: $dark-blue;
            border: 1px solid $dark-blue;
            background-color: $white-smoke;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            min-height: 2.5rem;
            font-family: $marianne;
            display: inline-flex;
            flex-direction: row;
            transition: background-color 150ms ease-in;

            &:hover {
                background-color: darken(#f5f5fe, 5%);
            }
        }
    }
}
