@use "sass:math";

$spinner: #d4e6f1;
$size: 5rem;

.load-container {
    height: 35rem;

    .spinner {
        position: absolute;
        top: calc(50% - #{math.div($size, 2)});
        left: calc(50% - #{math.div($size, 2)});
        width: $size;
        height: $size;
        animation: spinner 0.75s linear infinite;

        span {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        .spinner-inner-1 {
            background: linear-gradient(
                to right,
                rgba($spinner, 0) 0%,
                rgba($spinner, 0) 50%,
                $spinner 51%
            );
        }

        .spinner-inner-2 {
            background: linear-gradient(to top, rgba(#fff, 0) 0%, #fff 100%);
        }

        .spinner-inner-3 {
            $offset: $size * 0.1;
            top: $offset;
            left: $offset;
            width: $size - ($offset * 2);
            height: $size - ($offset * 2);
            background: #fff;
        }
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
