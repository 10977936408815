@import "../../../../App/variables";

.accordion-table {
    &__header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &:hover {
            color: $dark-blue;
        }
    }

    &__row {
        border-bottom: $border-grey-light;
    }

    &__container {
        border-bottom: $border-grey-light;
        border-top: $border-grey-light;
        max-height: 13rem;
        overflow: auto;
        tr {
            background-color: $background-default-grey-hover !important;
        }
    }
}
