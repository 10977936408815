@import "./../../../../App/variables";

.subcategory {
    // margin: $spacing-4;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        padding: $spacing-3 0;
        border-bottom: solid 0.0625rem $default-grey;
        border-top: solid 0.0625rem $default-grey;
        @media print {
            border-top: none !important;
            display: flex !important;
            align-items: flex-start;
            justify-content: space-around;
            padding: $spacing-1 !important;
        }
        .source {
            font-size: 0.8rem !important;
            @media print {
                text-align: end !important;
            }
            @media print {
                &__label,
                &__name,
                &__updated {
                    font-size: 0.8rem !important;
                    color: $grey !important;
                }
            }
        }
        table {
            margin-bottom: $spacing-4;
        }
    }

    &__title {
        margin-top: 0;
        font-size: $font-size-base;
        font-family: $marianne;
        line-height: 1.5rem;
        font-weight: 700;
        text-align: left;
        color: $dark-grey;
    }
}
