@import "../App/variables";
@import "../App/placeholders";

.user-feedback {
    width: 40em;
    max-width: 100%;
    margin: $spacing-5 auto;

    &--fullwidth {
        width: 100%;

        .container {
            padding: 0;
        }
    }

    &__panel {
        background: $white;
        border: 1px solid #dfdff1;
    }

    &__useful {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-2 $spacing-4;
        font-size: 1.75rem;
        font-family: "Marianne", sans-serif;
        font-style: normal;
        font-weight: bold;
        color: $white;
        background: $dark-blue;
        cursor: pointer;

        .legend-text {
            font-size: $font-size-base;
            cursor: pointer !important;
        }
    }

    &__thumbs {
        display: flex;
        align-items: center;
        font-size: 1.5rem;

        input {
            width: 0;
            height: 0;
            opacity: 0;
        }

        & .radio {
            margin-left: $spacing-4;
            margin-bottom: 0;
            transition: transform $duration-1;

            &:hover {
                transform: scale(1.1);
                color: $white;
            }

            .selected {
                transform: scale(1.3);
                color: $white;
            }
        }
    }
    .icon {
        background: transparent;
        border: none;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }
    .question {
        margin: $spacing-4 0;
        padding: $spacing-2 0;
        font-size: $font-size-base-small;
        color: $dark-grey;
        font-family: $marianne;
        font-weight: bold;
        line-height: 1.5rem;
    }
    .yes-no-btns {
        display: flex;
        align-items: center;
        padding: $spacing-4 0;
        gap: $spacing-2;
        button {
            color: $dark-blue;
            padding: 4px 10px;
            font-size: 1rem;
            font-family: $marianne;
            font-weight: 400;
            background: #e5e5f4;
            line-height: 1.5rem;
            border-radius: 6px;
            border: none;
            cursor: pointer;
        }
        .active {
            border: 2px solid $dark-blue;
        }
        button:hover {
            border: 2px solid $dark-blue;
            border-style: dashed;
        }
    }
    &__comment {
        padding: $spacing-4;
        font-size: $font-size-base-small;
        color: $dark-grey;
        font-family: $marianne;

        label {
            font-weight: bold;
        }

        textarea {
            margin: $spacing-4 0;
        }
    }

    & .textarea {
        border: $border-grey-light;
    }

    &__rating {
        margin-bottom: $spacing-6;

        p {
            font-weight: bold;
        }
    }

    &__rates {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 0;
        gap: 0.5rem;
    }

    &__rate {
        position: relative;
        display: flex;
        justify-content: center;

        &:not(:last-of-type) {
            margin-right: $spacing-1;
        }

        &-radio {
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            opacity: 0;
            z-index: 1;
        }

        &-span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            color: $dark-blue !important;
            border: $border-grey-light;
            border-radius: 6px;
            background: #e5e5f4;
            font-family: $marianne;
            cursor: pointer;
            transition: background-color $duration-2;

            &.active {
                border: 2px solid $dark-blue;
            }
            &:hover {
                border: 2px solid $dark-blue;
                border-style: dashed;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;

        .button {
            font-size: 1rem;
            line-height: 1.2rem;
            color: white;
            font-weight: 400;
            background-color: $dark-blue;
        }
        .button:hover{
            color: white;
            font-weight: 500;
        }
        .button:disabled{
            color: $dark-blue;
            background-color: #dfdff1;
        }
    }
}
