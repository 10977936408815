@import "../../App/variables";

.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    button.close-menu {
        z-index: 10010;
        display: none;
        position: fixed;
        top: 20px;
        right: 20px;
        content: "Fermer ✕";
        cursor: pointer;
    }
    @media only screen and (max-width: 600px) {
        .custom-dropdown-menu {
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            padding-top: 50px !important;
            width: 100vw !important;
            height: 100vh !important;
            margin-top: 0 !important;
        }
        button.close-menu {
            display: block;
            background-color: transparent;
            border: none;
        }
    }
}

.custom-dropdown-button {
    background-color: #eeeeee;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-family: "Marianne", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding: 2px $spacing-4;
    width: 100%;

    cursor: pointer;
    &:focus,
    &:active {
        outline: 5px solid #0a76f6 !important;
        outline-offset: 2px !important;
        outline-width: 2px !important;
    }
}

.custom-dropdown .custom-dropdown-menu {
    -webkit-box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.3);
    top: 100%;
    left: 0;
    position: absolute;
    padding: 15px;
    margin-top: 5px;
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    width: 480px;
    max-width: 100%;
    z-index: 1000;
    .filter__siege_button {
        input {
            box-sizing: border-box;
            padding: $spacing-4 !important;
            accent-color: $dark-blue !important;
            width: 1.5rem !important;
            height: 1.5rem !important;
            border-radius: 5px;
            cursor: pointer;
            border-color: $dark-blue !important;
            flex-wrap: wrap;
        }
    }
    .custom-dropdown-menu-filters{
        max-height: 400px;
        overflow-y: auto;
        padding:4px ;
        margin-bottom: $spacing-5;
        width: 100%;

    }
    .form-inputs {
        display: flex;
        flex-direction: row;
        gap: $spacing-2;
        margin: $spacing-3 0;
        input {
            font-family: $marianne;
            color: $default-text-gey;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
    .form-date-inputs {
        font-family: $marianne;
        color: $default-text-gey;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .form-buttons,
    .custom-dropdown-control-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button {
            justify-content: center;
            align-items: center;
            border: none;
            font-family: $marianne;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.75rem;
            background-color: white;
            margin-top: $spacing-5;
            color: $dark-blue;
            cursor: pointer;
            &.bordered {
                border: 1px solid $dark-blue !important;
            }
        }
    }
}

.custom-dropdown .dropdown-menu input {
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.custom-dropdown .dropdown-menu button {
    padding: 8px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
