/* ----------------------------------------------
 * Generated by Animista on 2019-6-14 13:46:9
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}
@keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-6-14 0:42:14
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(6px);
        transform: translateX(6px);
    }
    80% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
    }
    90% {
        -webkit-transform: translateX(-4px);
        transform: translateX(-4px);
    }
}
@keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(6px);
        transform: translateX(6px);
    }
    80% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
    }
    90% {
        -webkit-transform: translateX(-4px);
        transform: translateX(-4px);
    }
}

.swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 0.5s
        cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
        both;
}

.shake-horizontal {
    -webkit-animation: shake-horizontal 0.6s both;
    animation: shake-horizontal 0.6s both;
}
