@import "../../../../App/variables";
@import "../../../../App/placeholders";

.effectifs-establishment,
.effectifs-establishment .data:last-of-type {
    margin-bottom: 0;
}

.display_table_chart__switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $spacing-4 0;
    .toggle-label {
        font-family: $marianne;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: $title-grey;
        background-color: transparent;
        border: none;
        cursor: pointer;
        &-active {
            color: $dark-blue;
            font-weight: 600;
        }
    }

    @extend %flex-center;

    .react-toggle {
        margin-left: $spacing-2;
        border-color: $dark-blue !important;

        &--focus {
            box-shadow: none !important;
        }
        .react-toggle-thumb {
            border-color: $dark-blue !important;
            box-shadow: none !important;
        }
        .react-toggle-track {
            background-color: $white !important;
            border: 1px solid $dark-blue !important;
            width: 2.5rem !important;
            height: 1.5rem !important;
            &-x {
                right: 23px;
                z-index: 999;

                svg {
                    path {
                        fill: $dark-blue;
                    }
                }
            }
        }

        &--checked {
            .react-toggle-thumb {
                left: 17px;
            }
            .react-toggle-track {
                background-color: $dark-blue !important;

                width: 2.5rem !important;
                height: 1.5rem !important;
            }
            .react-toggle-track-check {
                left: 22px;
                z-index: 9999;
                svg {
                    path {
                        fill: $dark-blue;
                    }
                }
            }
            &:hover {
                .react-toggle-track {
                    width: 2.5rem !important;
                    height: 1.5rem !important;
                    background-color: $dark-blue !important;
                }
            }
        }
    }
}
