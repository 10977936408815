@import "../App/variables";
@import "../App/placeholders";

.data-sheet {
    .box-shadow {
        padding: $spacing-4;
        @media (max-width: 600px) {
            border: none;
            padding: 0;
            box-shadow: none !important;
        }
        @media print {
            box-shadow: none !important;
        }
    }
    &__main-content {
        padding: $spacing-2 $spacing-4;
        width: 99%;
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    @media print {
        .data-sheet__main-content {
            width: 100%; /* Set the width to 100% when printing */
        }
    }
    &.container {
        width: 100%;
        padding: 0 $padding-app-x;
    }
    .text-left {
        display: flex;
        justify-content: flex-end !important;
    }

    &-header {
        background-color: $white;
        @media (max-width: 768px) {
            padding: $spacing-2;
        }

        .is-bold {
            font-weight: bold !important;
            margin-right: 0.25rem;
        }

        .has-list-style:before {
            content: "• ";
        }
        .columns {
            margin-bottom: $spacing-2;
        }
        .column {
            padding-bottom: 0.75rem;
            padding-top: 0.25rem;
        }
        &__siren,
        &__siret {
            font-family: $marianne;
            font-weight: 400;
            line-height: 1.5rem;
            font-size: $font-size-base;
            color: #000000;
        }
        &-address {
            align-items: center;
        }
        &-address,
        &-top {
            display: flex;

            flex-wrap: wrap;

            gap: 0.25rem;
            font-family: $marianne;
            font-weight: 400;
            line-height: 1.25rem;
            font-size: $font-size-base;
            color: #000000;
            span {
                line-height: 1.25rem !important;
                margin-right: 5px;
            }
        }
        &__title {
            margin-bottom: $spacing-4;
            font-size: $font-size-datasheet-header;
            font-weight: bold;
            font-family: $marianne;
            text-transform: capitalize;
            line-height: 2.5rem;
            color: $title-grey;

            @media print {
                margin-top: 2rem !important;
                margin-bottom: $spacing-3 !important;
            }
        }
        &__bloc {
            display: flex;
            flex-direction: column;
            &_link {
                display: flex;
                align-items: center;
                gap: $spacing-1;
                margin-top: $spacing-3;
                font-family: $marianne;
                font-size: $font-size-base;
                font-weight: 400;
                line-height: 1.5rem;
                color: #000000;
                a {
                    color: $dark-blue !important;
                    font-family: $marianne;
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 1.5rem;
                    text-decoration: underline !important;
                    text-underline-offset: 4px;
                    text-decoration-thickness: 1px !important;
                    &:hover {
                        text-decoration-thickness: 2px !important;
                    }
                }
            }
        }
        &__status-closed {
            display: flex;
            font-family: $marianne;
            font-weight: 400;
            line-height: 1.5rem;
            font-size: $font-size-base;
            color: #000000;
            margin: 0.5rem 0;
        }

        &__status {
            display: flex;
            align-items: center;

            &-icon {
                margin-right: $spacing-2;
                @media print {
                    display: none !important;
                }
            }
            &__print {
                display: none;
                @media print {
                    display: inline-block !important;
                    font-family: $marianne;
                    font-weight: 400;
                    line-height: 1.5rem;
                    font-size: $font-size-base;
                    color: #000000;
                    text-transform: capitalize;
                }
            }
            &-date {
                font-family: $marianne;
                font-weight: 400;
                line-height: 1.5rem;
                font-size: $font-size-base;
                color: #000000;
                @media print {
                    text-align: start !important;
                }
            }
            @media print {
                display: flex !important;
                gap: 0 !important;
            }
        }

        &__naf {
            display: flex;
            flex-wrap: wrap;
            font-weight: 400;
            line-height: 1.75rem;
            color: #000;
            font-family: $marianne;
            font-size: $font-size-base;
            span {
                margin-right: 5px;
            }
        }

        @media not print {
            padding-top: $spacing-5;
            padding-bottom: 0;
        }
    }

    &__print-section {
        display: flex;
        justify-content: flex-end;
        margin: $spacing-2 0;
    }

    &__print-button {
        @extend %is-grey;
    }

    &__main-container {
        width: 100%;
        margin: auto;
        padding-top: 0;
        @media (max-width: 768px) {
            padding: 0;
        }
    }
    &__bloc_section {
        scroll-margin: $spacing-6;
        .data {
            @media print {
                display: flex !important;
                align-items: flex-start;
                justify-content: space-around;
            }
            .dd {
                @media print {
                    display: flex !important;
                    align-items: center;
                    justify-content: space-around;
                }
                .source {
                    text-align: end !important;
                }
            }
        }
        .dark-blue-title {
            font-family: $marianne;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 2.25rem !important;
            font-size: $font-size-title !important;
            color: $dark-blue !important;
            background-color: $white;
            padding: $spacing-3;
            text-transform: capitalize;
            @media print {
                padding: $spacing-1 !important;
            }
        }

        .section-header {
            border-bottom: solid 0.0625rem $default-grey;
            border-top: solid 0.0625rem $default-grey;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .icon {
                background-color: transparent;
                border: none;
                width: 2rem;
                height: 2rem;
                cursor: pointer;
            }
            @media print {
                border-width: 1px !important;
            }
        }
        .section-datas {
            padding: 0 $spacing-4;
            align-self: stretch;
            &-marches-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &-marches {
                display: flex;
                justify-content: end;
                .export-button {
                    @extend %is-dark-blue;
                    font-family: $marianne;
                    font-size: $font-size-base-small;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.75rem;
                    display: flex;
                    gap: $spacing-1;
                    margin-top: $spacing-2;
                    cursor: pointer;
                    border: 1px solid $dark-blue !important;
                }
            }
            .is-centred {
                text-align: center;
            }
            .text {
                font-family: $marianne;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                color: $title-grey;
                a {
                    color: $dark-blue !important;
                    font-family: $marianne;
                    font-size: $font-size-base-small;
                    font-weight: 400 !important;
                    line-height: 1.5rem;
                    text-decoration: underline !important;
                    text-underline-offset: 4px;

                    &:hover {
                        text-decoration-thickness: 2px !important;
                    }
                }
            }
            &__list {
                margin-top: $spacing-3;
                font-family: $marianne;
                font-size: $font-size-base-small;
                font-weight: 400;
                line-height: 1.5rem;
                color: $title-grey;
                a {
                    color: $dark-blue !important;

                    text-decoration: underline !important;
                    text-underline-offset: 4px;
                    &:hover {
                        text-decoration-thickness: 2px !important;
                    }
                }
                .list-item {
                    .dt-title {
                        display: list-item;
                        list-style-type: disc !important;
                        margin-left: $spacing-5 !important;
                    }
                }
            }

            &__list-description {
                font-size: $font-size-base-small;
                margin-bottom: $spacing-3;
                font-family: $marianne;
            }
            &__list-items {
                margin: 0 $spacing-5;
                list-style-type: disc;
                color: $dark-blue;
                @media print {
                    display: none;
                }
            }

            &__list-item {
                margin-bottom: $spacing-2;
            }

            .dt {
                font-weight: 700;
            }
        }
    }
    &__section {
        scroll-margin: $spacing-6;
        margin: $spacing-5 0;
        background-color: $white;
        border-radius: $border-radius-2;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .section-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background: $white;
            position: relative;
            padding: 0 $spacing-5;

            &:before {
                content: "";
                background: white;
                position: absolute;
                bottom: -0.5em;
                height: 0.5em;
                width: 110%;
                left: 0;
            }

            > .icon {
                color: $primary;
                width: 1.15rem;
                height: 1.25rem;
                margin-right: $spacing-2;
                padding: $spacing-4;
                border-radius: 50%;
                background-color: $white-smoke;
            }

            > .title {
                font-family: $roboto;
                font-weight: bold;
                font-size: $font-size-section-title;
                color: $primary;
                background-color: $white;
                padding-top: $spacing-4;
                text-transform: capitalize;
            }
        }

        .section-datas {
            box-shadow: $box-shadow-spread;
            padding: $spacing-5;
            align-self: stretch;

            &__list {
                margin-top: $spacing-3;
            }

            &__list-description {
                font-style: italic;
                font-size: $font-size-small;
                margin-bottom: $spacing-3;
            }

            &__list-item {
                margin-bottom: $spacing-2;
            }

            .dt {
                font-weight: 700;
            }
        }

        > .dl {
            margin-bottom: 0;
        }

        > .dd,
        > .dt {
            padding: $spacing-4 0;
            word-wrap: break-word;
            color: $grey-tundora;
        }
    }

    small {
        display: block;
        margin-bottom: $spacing-4;
    }
}
