@import "../../App/variables";

.page {
    a:hover {
        color: $primary;
        text-decoration: underline;
    }

    .faq {
        &__section {
            margin-bottom: $spacing-6;
        }

        &__title {
            padding: $spacing-2 $spacing-2 $spacing-1;
            color: #fff !important;
            background: $dark-blue;
        }

        &__question {
            padding: $spacing-2;
            font-size: $font-size-section-title;
            font-weight: bold;
            background: transparent;
            border: none;
            border-radius: $border-radius-1;
            cursor: pointer;
            text-decoration: underline;


            &:focus {
                outline: none;
                text-decoration: underline;
            }
        }

        &__question-text::before {
            content: " ";
            display: inline-block;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid currentColor;
            vertical-align: middle;
            margin-right: 0.7rem;
            transform: translateY(-2px);
            transition: transform 0.2s ease-out;
        }

        &__question-text.open::before {
            transform: rotate(90deg) translateX(-3px);
        }

        &__reponse {
            margin-left: $spacing-4;
            animation: appear 0.5s ease-in-out;
        }
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
