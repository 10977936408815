@import "fonts";
@import "variables";
@import "placeholders";
@import "helpers";
@import "animations";
@import "typography";
@import "pages";
@import "~bulma/bulma";
@import "~bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio";
@import "print";

html {
    min-height: 100%;
    scroll-behavior: smooth;
}

body {
    position: relative;
    height: 100%;
    margin: 0;
    color: $text-grey-dark;
    background-color: $app-container-background;
}

a {
    color: $primary;

    &:hover {
        text-decoration: none;
    }
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .app-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &.ie11 {
            min-height: calc(100vh - 320px);
        }

        @media print {
            padding-top: $spacing-4;
        }

        .bg-info a {
            color: white;
            text-decoration: underline;
        }
    }
}

.table {
    border: 0;
    td.table-cell,
    th.table-cell {
        &--center-cell {
            text-align: center;
        }

        &--nowrap,
        &--nowrap a {
            white-space: nowrap;
        }
        &--sm-cell {
            font-size: 0.5rem !important;
            align-items: center !important;
        }
    }
}

.container {
    width: 100%;
}

.button {
    height: initial;
}

.title {
    @extend .mb-4;
}

.icon {
    &--success {
        color: $success;
    }

    &--danger {
        color: $danger;
    }
}
.sourceApi {
    display: flex;
    align-items: flex-end !important;
    justify-content: center;
    color: black;
    span {
        font-size: 0.5rem;
        color: #6a6af4 !important;
    }
}
