@import "~bulma/sass/helpers/spacing";

/* COLORS */
$deep-blue: #284293;
$cobalt-blue: #004aad;
$carolina-blue: #22a1e2;
$blue: #2980b9;
$dark-blue: #000091;
$background-default-grey-hover: #f6f6f6;
$turquoise: #2aa995;
$faced-blue: #5499c6;
$light-sky-blue: #d4e6f1;
$red-bright: #e74c3c;
$red-washed: rgba(189, 10, 10, 0.14);
$green-washed: rgba(34, 137, 31, 0.2);
$green-forest: #22891f;
$yellow: #ffc107;
$white-alabaster: #fafafa;
$white-smoke: #ffffff;
$grey-geyser: #dde1e5;
$contrast-grey: #eeeeee;
$grey: #808080;
$title-grey: #161616;
$grey-tundora: #666666;
$dark-grey: #363636;
$default-grey: #dddddd;
$default-text-gey: #3a3a3a;
$black-grey: #4a4a4a;
$blue-france: #000091;

$primary: $blue;
$secondary: $turquoise;
$text-grey-dark: $grey-tundora;
$info: $turquoise;
$danger: $red-bright;
$info-success: $green-forest;
$info-success-bg: $green-washed;
$info-error: $red-bright;
$info-error-bg: $red-washed;
$info-neutral: $white-alabaster;
$info-neutral-bg: $turquoise;
$app-container-background: $white-smoke;
$footer-bg: $grey-geyser;
$footer-link: $grey-tundora;
$sidebar-establishment-bg: $white-alabaster;
$rating-gradient: #e21717, #e75907, #e38906, #eeb12c, #f0d828, #b3dc04, #71d903,
    #30d502, #02d112, #01ce4e, #00ca88;

/* GRADIENT */
$landing-page-gradient: linear-gradient(
    90deg,
    rgba(84, 88, 151, 1) 0%,
    rgba(67, 102, 170, 1) 45%
);

/* BORDERS */
$border-grey-light: 1px solid $grey-geyser;

/* BORDER-RADIUS */
$border-radius-1: 0.25rem;
$border-radius-2: 0.325rem;

/* SHADOWS */
$box-shadow-spread: 0 0 6px rgba(100, 100, 100, 0.06),
    0 0 15px rgba(100, 100, 100, 0.15);
$box-shadow-awesome-table: 2px 2px 6px $grey-geyser;
$box-shadow-header: 0 2px 3px rgba(0, 0, 0, 0.1);
$box-shadow-focus: inset 0 0 2px rgba(41, 128, 185, 1);

/* FONT-SIZE */
$font-size-datasheet-header: 2rem;
$font-size-section-title: 1.25rem;
$font-size-section-subtitle: 1.15rem;
$font-size-base: 1rem;
$font-size-naf: 1.125rem;
$font-size-small: 0.8rem;
$font-size-base-small: 0.875rem;
$font-size-title: 1.5rem;

/* TYPOGRAPHY */
$segoe: "Segoe Pro", sans-serif;
$evolventa: "Evolventa", sans-serif;
$roboto: "Roboto", sans-serif;
$marianne: "Marianne", arial, sans-serif;


/* SPACING (values from Bulma) */
$spacing-1: map-get($spacing-values, "1"); // 0.25rem
$spacing-2: map-get($spacing-values, "2"); // 0.5rem
$spacing-3: map-get($spacing-values, "3"); // 0.75rem
$spacing-4: map-get($spacing-values, "4"); // 1rem
$spacing-5: map-get($spacing-values, "5"); // 1.5rem
$spacing-6: map-get($spacing-values, "6"); // 3rem

$padding-app-x: $spacing-4;

/* ANIMATIONS */
$duration-1: 200ms;
$duration-2: 300ms;

/* MEDIA QUERY CUSTOM */
$landing-page-breaking: 1366px;
$landing-page-mobile-breaking: 400px;

@import "~bulma/sass/utilities/all";
