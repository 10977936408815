@import "../../App/variables";

.app .button {
    display: flex;
    height: 2.5rem;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        &--before-label {
            margin-left: $spacing-2;
        }
        &--center-label {
            text-align: center;
        }
    }
}
