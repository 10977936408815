@import "../../App/variables";

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    color: $grey-tundora !important;
    background-color: $white;
    padding: 1px 24px 1px 24px;
    min-height: 45px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: $font-size-small !important;
    line-height: 1.25rem !important;
    font-family: $marianne;
    a {
        color: $footer-link !important;
        text-decoration: underline;

        &:hover {
            color: $dark-blue !important;
        }
    }
    svg {
        margin-left: 10px;
    }

    & > * {
        display: inline-block;
        margin-right: 10px;
    }

    .crumb:last-child:after {
        display: none;
    }
}
